<template>
  <div></div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
  },
  data() {
    return {
      
    }
  },
  beforeMount(){
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      localStorage.clear();

      // Redirect to login page
      this.$store.dispatch('users/logout').then(_=>{
        this.$router.push({ name: 'auth-login' })
      })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
*{
  font-family: "Inter", sans-serif !important;
}
.logo{
  width: 200px;
}
@media (max-width: 992px) {
  .logo{
    width: 100px;
  }
  .auth-inner{
    display: flex !important;
    justify-content: center !important;
  }
}
.auth-wrapper{
  background: url('../../../assets/images/banner/dark_bakground.png') no-repeat;
  background-size: cover;
}
.auth-bg{
  background-color: #5a5a5a32 !important;
  color: #fff !important
}
// .form-control{
//   background-color:rgba(0,0,0,0) !important;
//   border:1px solid #888 !important;
//   height: 50px;
// }
.inner-addon { 
    position: relative; 
}
.inner-addon .feather-mail,.feather-lock {
  position: absolute;
  top: 16px;
  left: 10px;
  // pointer-events: none;
}
.left-addon input  { padding-left:  40px; color: #fff !important }
.password-addon {padding-left:  40px !important; border-top-left-radius: 5px !important; border-bottom-left-radius: 5px !important; color: #fff !important}

</style>
